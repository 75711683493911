import styled from 'styled-components';
import {
  mediumBreakpointMinus,
  mediumBreakpoint,
  largeBreakpoint,
  transitionTime,
} from '../../../constants';

export const ImageWrapperWrap = styled.div`
  transition: all ${transitionTime};
  width: calc(100% - 320px);
  height: ${(props) =>
    !props.showThumbnailTrack ? '72vh' : 'calc(72vh + 60px)'};
  display: flex;
  flex-direction: column;
  opacity: ${(props) => props.opacity};
  @media only screen and (max-width: ${mediumBreakpointMinus}) {
    width: 80vw;
    height: ${(props) =>
      !props.showThumbnailTrack ? '55vh' : 'calc(55vh + 50px)'};
    align-items: center;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 72vh;
  @media only screen and (max-width: ${mediumBreakpointMinus}) {
    height: 55vh;
  }
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  div:first-child {
    width: 100%;
    height: 100%;
  }
`;

export const WorkWrapper = styled.div`
  width: 100%;
  max-width: 85vw;
  height: 100%;
  margin: 30px auto 0 auto;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  @media only screen and (max-width: ${mediumBreakpointMinus}) {
    max-width: ${mediumBreakpoint};
    flex-direction: column;
    flex-wrap: unset;
    width: 100vw;
    align-items: center;
  }
  @media only screen and (min-width: ${largeBreakpoint}) {
    max-width: ${largeBreakpoint};
  }
`;

export const ThumbnailsTrack = styled.ul`
  height: 40px;
  background-color: gray;
  margin: 25px 0 0 0;
`;

export const HorizontalMainImage = styled.img`
  width: 100%;
`;

export const VerticalMainImage = styled.img`
  height: 100%;
`;

export const NavButtonBase = styled.div`
  position: fixed;
  top: 34vh;
  z-index: 5;
  @media only screen and (max-width: ${mediumBreakpointMinus}) {
    top: 50vh;
  }
  @media only screen and (max-width: 350px) {
    top: 60vh;
  }
  a {
    width: 25px;
    font-size: 16px;
    font-weight: 300;
    display: block;
    transform: scaleY(6);
    transition: all ${transitionTime};
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const BackButton = styled(NavButtonBase)`
  left: 0;
  a {
    margin-left: 20px;
    @media only screen and (max-width: ${mediumBreakpointMinus}) {
      margin-left: 5px;
    }
  }
`;

export const ForwardButton = styled(NavButtonBase)`
  right: 0;
  a {
    text-align: right;
    margin-right: 20px;
    @media only screen and (max-width: ${mediumBreakpointMinus}) {
      margin-right: 5px;
    }
  }
`;
