/* eslint-env browser */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import loadable from '@loadable/component';
const innerHeight = loadable(() => import('ios-inner-height'));

import _filter from 'lodash.filter';
import _first from 'lodash.head';

import { imageBase } from '../constants';
import { getBackAndNext, getRatioNew, sendAnalytics } from '../util';
import Layout from './layout';
import SEO from './seo';
import * as S from './sub-components/styled-components/work-styles.js';

const WorkText = loadable(() => import('./sub-components/workText'));
const RenderAllImages = loadable(() =>
  import('./sub-components/workSubComponents')
);
const VideoModal = loadable(() => import('./sub-components/videoModal'));
const ImageRotateModal = loadable(() =>
  import('./sub-components/imageRotateModal')
);
const SliderThumbnails = loadable(() =>
  import('./sub-components/sliderThumbnails')
);

class Work extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 500,
      width: 320,
      windowHeight: 600,
      windowWidth: 600,
      sizesCalculated: false,
      modalIsOpen: false,
      videoModalIsOpen: false,
      currentImage: 0,
      currentVideo: 0,
      opacity: 0,
    };
    this.imageWrapper = React.createRef();
  }

  disableRightClick() {
    return false;
    // e.preventDefault();
  }

  setCurrentImage = (index) => {
    const {
      work: { title },
    } = this.props.pageContext;
    sendAnalytics(
      {
        event_category: 'Navigate',
        event_label: `View Carousel Image for ${title}`,
        image_number: index + 1,
      },
      'click'
    );

    this.setState({
      currentImage: index,
    });
  };

  setCurrentVideo = (vimeoId) => {
    this.setState({
      currentVideo: vimeoId,
      videoModalIsOpen: true,
    });
  };

  _backAndNextUrls = { back: '', next: '' };

  windowInnerHeight = () => null;

  windowInnerWidth = () => null;

  handleOpenModal = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  closeVideoModal = () => {
    this.setState({
      videoModalIsOpen: false,
    });
  };

  updateWindowDimensions = (opacity = 1) => {
    if (this.imageWrapper.current) {
      const imageBox = this.imageWrapper.current.getBoundingClientRect();

      this.setState({
        width: imageBox.width,
        height: imageBox.height,
        sizesCalculated: true,
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
        opacity: opacity,
      });
    }
  };

  componentDidMount() {
    this.windowInnerHeight = () =>
      typeof window !== 'undefined' ? innerHeight() : 800;
    this.windowInnerWidth = () =>
      typeof window !== 'undefined' ? window.innerWidth : 500;

    this.updateWindowDimensions(0);
    setTimeout(() => {
      this.updateWindowDimensions(1);
    }, 180);

    window.addEventListener('resize', this.updateWindowDimensions);
    window.addEventListener('orientationchange', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    window.removeEventListener(
      'orientationchange',
      this.updateWindowDimensions
    );
  }

  render() {
    if (!this.props.pageContext.work) {
      return null;
    }

    const {
      work: { keywords, seoDescription },
      works,
      index,
      length,
      smallWorksURLs,
      smallWorksLength,
      isSmallWork,
    } = this.props.pageContext;

    let { images, work } = this.props.pageContext;

    const showThumbnailTrack = images.length > 1;

    const videos = images.filter((image) => image.vimeoId);

    images = images.filter((image) => !image.vimeoId);

    this._backAndNextUrls = getBackAndNext(
      works,
      index,
      length,
      smallWorksURLs,
      smallWorksLength,
      isSmallWork
    );

    const zoomableImage = _first(_filter(images, (image) => image.zoomable));

    const {
      alt,
      title,
      smallImageSrc,
      smallImage2xSrc,
      smallImageWidth,
      smallImageHeight,
      isHorizontal,
      aspectRatio,
    } = zoomableImage;

    const keywordsParsed = keywords.split(',');
    const srcSet = smallImage2xSrc
      ? `${imageBase + smallImageSrc} 1x, ${imageBase + smallImage2xSrc} 2x`
      : null;

    const ratio = getRatioNew(
      smallImageHeight,
      smallImageWidth,
      this.state,
      aspectRatio
    );

    return (
      <Layout isWorkLayout>
        <SEO
          title={`Jennifer Moné Hill Artist - ${title}`}
          keywords={keywordsParsed}
          description={seoDescription}
        />
        <S.BackButton>
          <Link to={`/works/${this._backAndNextUrls.back}`}>{`<`}</Link>
        </S.BackButton>
        <S.ForwardButton>
          <Link to={`/works/${this._backAndNextUrls.next}`}>{`>`}</Link>
        </S.ForwardButton>

        <S.WorkWrapper onContextMenu={(e) => this.disableRightClick(e)}>
          {this.state.sizesCalculated ? (
            <S.ImageWrapperWrap
              opacity={this.state.opacity}
              aspectRatio={aspectRatio}
              showThumbnailTrack={showThumbnailTrack}
            >
              <S.ImageWrapper
                ref={this.imageWrapper}
                isHorizontal={isHorizontal}
                aspectRatio={aspectRatio}
                id="image-wrapper"
              >
                <RenderAllImages
                  images={images}
                  ratio={ratio}
                  state={this.state}
                  work={work}
                  currentImage={this.state.currentImage}
                />
              </S.ImageWrapper>
              {showThumbnailTrack && (
                <SliderThumbnails
                  images={images}
                  videos={videos}
                  work={work}
                  setCurrentImage={this.setCurrentImage}
                  setCurrentVideo={this.setCurrentVideo}
                />
              )}
            </S.ImageWrapperWrap>
          ) : (
            <S.ImageWrapper
              ref={this.imageWrapper}
              isHorizontal={isHorizontal}
              aspectRatio={aspectRatio}
            >
              {isHorizontal ? (
                <S.HorizontalMainImage
                  src={smallImageSrc}
                  srcSet={srcSet}
                  alt={alt}
                />
              ) : (
                <S.VerticalMainImage
                  src={smallImageSrc}
                  srcSet={srcSet}
                  alt={alt}
                />
              )}
            </S.ImageWrapper>
          )}
          <WorkText
            work={this.props.pageContext.work}
            handleOpenRotateModal={this.handleOpenModal}
          />
        </S.WorkWrapper>
        {this.state.modalIsOpen && (
          <ImageRotateModal
            modalIsOpen={this.state.modalIsOpen}
            closeModal={this.closeModal}
            src={smallImageSrc}
            srcSet={srcSet}
            alt={alt}
            title={title}
            isHorizontal={isHorizontal}
            windowHeight={this.state.windowHeight}
            windowWidth={this.state.windowWidth}
          />
        )}
        {this.state.videoModalIsOpen && (
          <VideoModal
            modalIsOpen={this.state.videoModalIsOpen}
            closeModal={this.closeVideoModal}
            alt={alt}
            title={title}
            vimeoId={this.state.currentVideo}
          />
        )}
      </Layout>
    );
  }
}

Work.propTypes = {
  pageContext: PropTypes.object,
};

export default Work;
